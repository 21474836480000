import React from "react";
import Paragraph from "../components/Paragraph";
import LongText from "../components/LongText";

const IntroText: React.FC = () => {
  return (
    <LongText
      background={"white"}
      titel={"Hämostaseologischer Intensivworkshop"}
    >
      <Paragraph>
        Liebe <strong>H</strong>ämostase <strong>I</strong>n <strong>C</strong>ritical <strong>C</strong>are - Interessierte
      </Paragraph>
      <Paragraph>Es ist uns eine grosse Freude, Sie wieder alle in St. Gallen zum 12. HICC® Meeting willkommen zu heissen. Diese Veranstaltung steht unter der Schirmherrschaft der Gesellschaft für Thrombose- und Hämostaseforschung (GTH) und bietet eine einmalige Gelegenheit, unser Wissen in diesem hochspezialisierten Bereich zu erweitern.</Paragraph>
      <Paragraph>Unser Programm ist reich an klassischen Vorträgen, die von führenden Experten auf dem Gebiet der Hämostaseforschung präsentiert werden. Diese Vorträge bieten wertvolle Einblicke in die neuesten Forschungsergebnisse und bewährte Praktiken in der Intensivmedizin.</Paragraph>
      <Paragraph>Besonders stolz sind wir beim 2025 Meeting führende Köpfe von drei «alteingesessenen» und angesehenen Arbeitsgruppen zu einer Paneldiskussion in St. Gallen begrüssen zu können:</Paragraph>
      <Paragraph>
        Prof. Ernest «Gene» Moore, Denver <br/>
        Prof. Dietmar Fries, Innsbruck <br/>
        Prof. Simon Stanworth, Oxford <br/>
      </Paragraph>
      <Paragraph>
        Neben den Vorträgen haben Sie die Möglichkeit, sich in Networking-Sessions auszutauschen und neue Kontakte zu knüpfen. Nutzen Sie diese Gelegenheit, um Ihre Erfahrungen zu teilen und von den Erfahrungen anderer zu lernen. Der Austausch von Ideen und das Knüpfen von Verbindungen sind entscheidend für den Fortschritt in unserem Fachgebiet.
      </Paragraph>
      <Paragraph>
        Wir würden uns sehr freuen, Sie zu diesem wunderbaren, interdisziplinären Intensivworkshop begrüssen zu dürfen.
      </Paragraph>
    </LongText>
  );
};

export default IntroText;
