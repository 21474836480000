import React from "react";
import styled from "styled-components";
import Title from "./Title";
import { colors, spacers } from "../styles/variables";
import Paragraph from "./Paragraph";

const Banner = styled.div`
  background: ${colors.red};
  width: 100vw;

  a {
    text-decoration: none;
    padding: ${spacers.mobile}px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0 auto;
    max-width: 1792px;
    color: white;

    > * {
      width: initial;
    }

    h1 {
      margin: auto 0;
      color: white;
      max-width: 60%;
    }

    img {
      margin-left: 32px;
      position: relative;
      right: 0;
      transition: all 1000ms;
    }

    &:hover {
      text-decoration: underline;

      img {
        right: -45px;
      }
    }
  }
`;

const BerlinBanner: React.FC = () => {
  return (
    <Banner>
      <div>
        <a href="https://hicc-berlin.com/" target="_blank">
          <Title>Workshop Berlin 2025</Title>
          <img src="/gestaltungselemente/arrowberlinbanner.svg"/>
        </a>
      </div>
      <Paragraph>
        <a href="/docs/HICC_flyer_2025.pdf" target="_blank">Flyer PDF</a>
      </Paragraph>
    </Banner>
  );
};

export default BerlinBanner;
