import React from "react";
import styled from "styled-components";

import Title from "../components/Title";
import LeadHeading from "../components/LeadHeading";
import ButtonLink from "../components/ButtonLink";

import { heights, breakpoints, spacers } from "../styles/variables";

const BGImageContainer = styled.div`
  background-image: url(/hintergrund/blood-vessels1280.jpeg);
  background-size: cover;
  background-repeat: no-repeat;
  min-height: calc(100vh - ${heights.header.mobile}px);
  width: 100vw;

  //might have to be changed
  margin-top: ${heights.header.mobile + "px"};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${spacers.mobile}px;
  //danger zone over

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 30px;

  > div:last-of-type {
    width: 100px;
    img {
      width: 100px;
    }
  }

  @media screen and (min-width: ${breakpoints.desktop}px) {
    background-image: url(/hintergrund/blood-vessels1920.jpeg);
  }

  @media screen and (min-width: 2560px) {
    background-image: url(/hintergrund/blood-vessels.jpeg);
  }

  div * {
    color: white;
  }

  > div {
    width: 100%;
    max-width: 1792px;
    margin: 0 auto;
  }

  @media screen and (min-width: ${breakpoints.tablet}px) {
    //dangers zone start
    padding: ${spacers.tablet}px;
    //danger zone over

    > div:last-of-type {
      width: 160px;
      img {
        width: 160px;
      }
    }
  }
  @media screen AND (min-width: ${breakpoints.desktop}px) {
    margin-top: ${heights.header.desktop + "px"};
    height: calc(100vh - ${heights.header.desktop}px);
    //dangers zone start
    padding: ${spacers.desktop}px;
    //danger zone over
  }
`;

const Landing: React.FC = () => {
  return (
    <BGImageContainer>
      <div>
        <Title>
          HICC<sup>®</sup> 2025
        </Title>
        <LeadHeading>
          Haemostasis in Critical Care St. Gallen
          <br />
          Datum: <ul>25. / 26. April 2025</ul>
          <br />
          Anmeldung ab Mitte Januar 2025
          <br />
          Teilnahme auch online möglich
          <br />
        </LeadHeading>

        {/*<ButtonLink url={"https://www.mci-online.org/secure/conreg_hicc2024/"}>*/}
        {/*  Anmeldung*/}
        {/*</ButtonLink>*/}
      </div>
      <div>
        <img src="/gestaltungselemente/gth-logo.png" />
      </div>
    </BGImageContainer>
  );
};

export default Landing;
