import React from "react";
import styled from "styled-components";
import { SponsorenData } from "./data";
import { spacers, breakpoints } from "../styles/variables";
import Title from "./Title";
import Sponsor from "./Sponsor";
import LeadHeading from "./LeadHeading";

const SponsorenDiv = styled.div`
  background: #fff;
  padding-top: ${spacers.tablet}px;

  > h1,
  h3 {
    max-width: 964px;
    margin: 0 auto;
    padding: 0 ${spacers.mobile}px ${spacers.tablet}px;

    @media screen and (min-width: ${breakpoints.tablet}px) {
      margin: 0 auto;
      padding: 0 ${spacers.tablet}px ${spacers.desktop}px;
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    padding: ${spacers.tablet}px ${spacers.mobile}px ${spacers.mobile}px;
    max-width: 1920px;
    margin: 0 auto;

    > a {
      height: 100%;
      max-height: 75px;

      > img {
        height: 100%;
      }
    }
  }

  .congress {
    max-width: 900px;

    a {
      display: block;
      width: 100%;
      height: auto;
      max-height: 100%;
    }
    img {
      max-width: 100%;
      height: auto;
    }
  }

  @media screen and (min-width: ${breakpoints.tablet}px) {
    padding-top: ${spacers.desktop}px;
    > div {
      padding: ${spacers.tablet}px;
    }
  }

  @media screen and (min-width: 1024px) {
    > div {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  @media screen and (min-width: ${breakpoints.desktop}px) {
    > div {
      padding: ${spacers.desktop}px;
    }
  }
`;

const Sponsoren: React.FC = () => {
  return (
    <>
      <SponsorenDiv>
        {/*<Title>Sponsoren</Title>*/}
        {/*<LeadHeading>Diagnostika Sponsoren</LeadHeading>*/}
        {/*<div>*/}
        {/*  {SponsorenData[0].map((sponsor, index) => (*/}
        {/*    <Sponsor*/}
        {/*      image={sponsor.image}*/}
        {/*      key={index}*/}
        {/*      name={sponsor.name}*/}
        {/*      url={sponsor.url}*/}
        {/*    />*/}
        {/*  ))}*/}
        {/*</div>*/}
        {/*<LeadHeading>Therapeutika Gold Sponsoren</LeadHeading>*/}
        {/*<div>*/}
        {/*  {SponsorenData[1].map((sponsor, index) => (*/}
        {/*    <Sponsor*/}
        {/*      image={sponsor.image}*/}
        {/*      key={index}*/}
        {/*      name={sponsor.name}*/}
        {/*      url={sponsor.url}*/}
        {/*    />*/}
        {/*  ))}*/}
        {/*</div>*/}
        
        <div className="congress">
          <LeadHeading>Fachverwandte Kongresse</LeadHeading>
          <a href="https://criticalcongress.episirus.org/bangkok/" target="_blank">
            <img src="/img/wcac2025.png" />
          </a>
        </div>
      </SponsorenDiv>
    </>
  );
};

export default Sponsoren;
